/* General App Styles */
.app {
  display: flex;
  flex-direction: column;
  background-image: url('./image-hotel.jpg'); /* Adjust path if necessary */
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  color: #ffffff; /* Light text color */
}

/* Header Styles */
.header {
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent dark background */
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content */
  position: relative; /* Allows absolute positioning of logo and button */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #ffffff; /* Light text color */
}

.header-buttons {
  position: absolute;
  right: 15px; /* Position the logout button to the right */
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #d32f2f;
}

.header .title {
  font-size: 1.5em;
  text-align: center;
  max-width: 70%; /* Adjust width as needed */
}

.logo-container {
  display: flex;
  align-items: center;
}

.company-logo {
  height: 100px;
  position: absolute;
  left: 5px;
  top: 1px;
}

.company-logo-2 {
  height: 55px;
  position: absolute;
  left: 110px;
  top: 20px;
}

.sidebar-toggle {
  position: absolute;
  right: 15px; /* Position the toggle button to the right */
}

/* Main Content */
.main-content {
  display: flex;
  flex: 1;
}

/* Sidebar Styles */
.sidebar {
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent dark background */
  color: #ffffff; /* Light text color */
  width: 250px;
  transition: width 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar.closed {
  width: 80px;
}

.sidebar-header {
  padding: 15px;
  display: flex;
  justify-content: flex-end;
}

.sidebar-content {
  overflow-y: auto;
  padding: 0 15px;
}

.module-box {
  margin-bottom: 10px;
}

.module-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent dark background */
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #4b5563;
}

.module-header:hover,
.module-header.active {
  background: rgba(0, 128, 128, 0.9); /* Different color when active */
}

/* Different colors for each module header */
.module-header.module-a {
  background: rgba(0, 0, 128, 0.8); /* Blue */
}

.module-header.module-b {
  background: rgba(0, 128, 0, 0.8); /* Green */
}

.module-header.module-c {
  background: rgba(128, 0, 0, 0.8); /* Red */
}

.module-header.module-d {
  background: rgba(128, 128, 0, 0.8); /* Yellow */
}

.module-header.module-e {
  background: rgba(128, 0, 128, 0.8); /* Purple */
}

.module-header:hover {
  opacity: 0.9;
}

.child-buttons {
  margin-top: 5px;
}

.child-button {
  display: block;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent dark background */
  color: #ffffff; /* Light text color */
  border: none;
  text-align: left;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #4b5563;
  margin-bottom: 5px;
  transition: background 0.3s;
}

.child-button:hover,
.child-button.active {
  background: rgba(0, 128, 128, 0.9); /* Different color when active */
}

/* Question List and Buttons */
.question-list {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 20px;
  border: 1px solid #ddd;
}

.content-header h1 {
  color: #333;
  margin-bottom: 20px;
  font-size: 1.5em;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  text-align: center;
}

.question-item {
  color: #333;
  background: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.2s, background 0.3s;
  font-size: 1.2em; /* Make the text larger */
  font-weight: bold; /* Make the text bold */
  line-height: 1.5; /* Increase line height for better readability */
}

.question-item:hover {
  transform: scale(1.02);
  background: #f0f8ff; /* Light background color on hover */
}

.answer-button {
  margin: 10px 5px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background: #ddd;
  color: #333;
  transition: background 0.3s;
}

.answer-button.selected-true {
  background: #4caf50;
  color: white;
}

.answer-button.selected-false {
  background: #f44336;
  color: white;
}

.answer-button:hover {
  background: #bbb;
}

.submit-btn-final {
  display: block;
  width: 100%;
  padding: 15px;
  background: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: auto; 
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 15px;
  background: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.submit-btn:hover {
  background: #45a049;
}

body {
  background: linear-gradient(to right, #00c6ff, #0072ff);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.login-form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.login-form form {
  display: flex;
  flex-direction: column;
}

.login-form label {
  margin-bottom: 10px;
}

.login-form input {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-form .error {
  color: red;
  margin-bottom: 10px;
}

.login-form button {
  padding: 10px;
  background-color: #0072ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #005bb5;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.navigation-buttons button {
  background-color: white;
  border: none;
  color: #4CAF50;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;
}

.navigation-buttons button:hover {
  background-color: #4CAF50;
  color: black;
  border: 2px solid white;
}

.navigation-buttons button:disabled {
  background-color: #d3d3d3;
  color: #808080;
  cursor: not-allowed;
  border: 2px solid #d3d3d3;
}

.score-display{
  color: black;
  font-weight: bold;
}

.history-content {
  margin: 20px;
  font-family: 'Arial', sans-serif;
}

.history-content h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #009879;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  font-family: 'Arial', sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.history-table thead tr {
  background-color: #75ffe3;
  color: #ffffff;
  text-align: center;
}

.history-content table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  border-radius: 8px;
  overflow: hidden;
}

.history-content th,
.history-content td {
  padding: 12px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  border: 1px solid black;
  color: #000000;
  font-weight: bold;
}

.history-content th {
  background-color: #75ffe3;
  font-weight: bold;
  color: #333;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 10px 12px;
}

.history-content th:last-child { /* Target the "Time" header */
  width: 20%; /* Adjust the width as needed */
  color: #000000;
  font-weight: bold;
}

.history-content td:last-child { /* Target the "Time" column cells */
  width: 20%; /* Make sure the cells follow the header's width */
  color: #000000;
  font-weight: bold;
}

.history-content tr:nth-child(even) {
  background-color: #f2f2f2;
  color: #000000;
  font-weight: bold;
}

.history-content tbody tr:hover {
  background-color: #e9e9e9;
  cursor: pointer;
  color: #000000;
  font-weight: bold;
}

.history-content tfoot td {
  padding: 15px;
  text-align: center;
}

.history-submit {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.history-submit:hover {
  background-color: #45a049;
}

.submit-btn-final {
  background-color: #008CBA;
  border: none;
  color: white;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;
  margin-top: 20px;
}

.submit-btn-final:hover {
  background-color: white;
  color: black;
  border: 2px solid #008CBA;
}

.history-submit {
  background-color: #008CBA;
  border: none;
  color: white;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;
  margin-top: 20px;
}

.history-submit:hover {
  background-color: white;
  color: black;
  border: 2px solid #008CBA;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 90%;
}

.modal-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.modal-content p {
  font-size: 18px;
  margin-bottom: 30px;
  color: #555;
}

.modal-button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #0056b3;
}

.modal-button:focus {
  outline: none;
}


