/* Ensure styles apply only within the login component */
.login-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./login-background.jpg') no-repeat center center fixed;
   background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure it's above other content */
}
.login-container {
  max-width: 400px;
  width: 100%;
  padding: 40px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.login-title {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.login-input {
  margin-bottom: 20px;
  color: black;
  background-color: white;
}

.login-input .MuiInputBase-input {
  color: black;
}

.login-button {
  background-color: #9c27b0;
  color: white;
  width: 100%;
}

.login-footer {
  margin-top: 20px;
  text-align: center;
  color: #fff;
}

.login-footer a {
  color: #9c27b0;
}
